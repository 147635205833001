<template>
  <div>
      <c-tab
        type="tabcard"
        align="left"
        :height="tabHeight"
        :inlineLabel="true"
        :tabItems.sync="tabItems"
        :editable="editable"
        :addTab="!tabAddDisabled"
        :removeTab="!tabRemoveDisabled"
        removeConditionCol="accidentStatusCd"
        removeConditionVal="ISPC000005"
        v-model="tab"
        @addTab="addTab"
        @removeTab="removeTab"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :popupParam.sync="popupParam"
            :setSize.sync="setSize"
            @setReportTab="setReportTab"
          />
        </template>
      </c-tab>
      <q-dialog v-model="prompt" persistent>
        <q-card style="min-width: 350px">
          <q-card-section>
          </q-card-section>

          <q-form ref="editForm">
            <q-card-section class="q-pt-none">
              <!-- 보고명 -->
              <c-text
                required
                label="LBL0002311"
                name="reportName"
                v-model="reportName">
              </c-text>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <!-- 보고유형 -->
              <c-select
                required
                type="edit"
                :editable="editable"
                :comboItems="comboItems"
                itemText="codeName"
                itemValue="code"
                label="LBL0002312"
                name="reportTypeCd"
                v-model="reportTypeCd">
              </c-select>
            </q-card-section>
          </q-form>
          <q-card-actions  actions align="right" class="text-primary">
            <!-- 취소 -->
            <q-btn flat :label="setLabel('LBLCANCEL')" @click="cancelReportName" />
            <!-- 추가 -->
            <q-btn flat :label="setLabel('LBLADD')" @click="setReportName" />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mixinConvert from '@/js/mixin-template-convert'
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar'
export default {
  name: 'process-accident-tab',
  mixins: [mixinConvert],
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        reportName: '',
        iimReportGroupId: '',
      }),
    },
    contentHeight: null, 
  },
  data() {
    return {
      tab: 'first',
      tabItems: [],
      editable: true,
      prompt: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      comboItems: [],
      reportName: '',
      reportTypeCd: null,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      setSize: '',
      beforeStatusCd: '',
      idx: 0,
    };
  },
  computed: {
    tabHeight() {
      let height = this.contentHeight;
      if (height === 'auto') {
        height = 900;
      }
      return String(height - 56);
    },
    // isBeforeFinishReport() {
    //   return  
    // },
    tabAddDisabled() {
      return (!this.popupParam.iimAccidentId || this.popupParam.stepCd === 'ISPC000001' 
        || this.$_.findIndex(this.tabItems, {reportTypeCd: 'IRC0000010'}) >= 0) || this.$_.findIndex(this.tabItems, {reportTypeCd: 'IRC0000010', accidentStatusCd: 'ISPC000005'}) >= 0
        || this.beforeStatusCd !== 'ISPC000005' 
    },
    tabRemoveDisabled() {
      return (this.tabItems.length === 1 && this.popupParam.stepCd !== 'ISPC000001') || this.$_.findIndex(this.tabItems, {reportTypeCd: 'IRC0000010', accidentStatusCd: 'ISPC000005'}) >= 0
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.iim.accident.process.reportTab.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.reportTab.insert.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.reportTab.delete.url;
      this.$comm.getComboItems('IIM_REPORT_CD').then(_result => {
        this.comboItems = this.$_.reject(_result, {code: 'IRC0000001'});
      });
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.iimReportGroupId) {
        this.tabItems = [];
        this.$http.url = this.$format(this.getUrl, this.popupParam.iimReportGroupId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tabItems = _result.data;
          this.tab = _result.data[0].iimReportId
          this.beforeStatusCd = _result.data[_result.data.length-1].accidentStatusCd
          this.$_.forEach(this.tabItems, item => {
            item.name = item.iimReportId;
            item.label = item.reportName;
            item.icon = 'folder'
            item.component = () => import(`${'@/pages/sop/iim/processAccidentDetail.vue'}`)
            item.key = uid()
          })
        },);
      } else {
        this.tabItems = [
          // 최초보고
          { key: uid(), name: 'first', icon: 'folder', label: 'LBL0002313', component: () => import(`${'@/pages/sop/iim/processAccidentDetail.vue'}`) },
        ]
      }
    },
    tabClick(tab) {
      this.tab = tab.name;
      if (this.popupParam.iimAccidentId) {
        this.popupParam.stepCd = tab.accidentStatusCd
        this.popupParam.iimAccidentId = tab.iimAccidentId
      }
      this.setSize = uid();

    },
    addTab() {
      this.prompt = true;
    },
    cancelReportName() {
      this.reportName = '';
      this.prompt = false;
    },
    setReportName() {
      let saveData = {
        firstIimAccidentId: this.popupParam.iimReportGroupId,
        reportName: this.reportName,
        reportTypeCd: this.reportTypeCd,
        iimReportId: uid(),
        regUserId: this.$store.getters.user.userId,
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          this.$http.url = this.insertUrl;
          this.$http.type = 'POST';
          this.$http.param = saveData
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.prompt = false;
            this.reportName = '';
            this.reportTypeCd = null;
            this.getDetail();
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      })
    },
    removeTab(tab) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        // 제외하실 경우 해당 보고에 해당하는\n\r사고 정보가 삭제됩니다.\n\r진행하시겠습니까?
        message: 'MSG0000700',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, tab.iimAccidentId, tab.iimReportId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            if (this.tabItems.length === 1) {
              this.$emit('closePopup')
            } else {
              this.$set(this.tabItems, this.$_.findIndex(this.tabItems, {name: tab.iimReportId}), null)
              this.tab = this.tabItems[0].name;
            }
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setReportTab(data) {
      if (!this.popupParam.iimAccidentId) {
        this.popupParam.iimAccidentId = data.groupId
        this.popupParam.iimReportGroupId = data.groupId
        this.popupParam.stepCd = 'ISPC000001'
      } else {
        this.popupParam.stepCd = data.stepCd
        this.beforeIimAccidentId = data.iimAccidentId
      }
      this.getDetail();
    },
  }
};
</script>